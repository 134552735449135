/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_cognito_region": "us-west-2",
    //for phrql Production enable below
    "aws_user_pools_id": "us-west-2_hUNK2Ge9c",
    //for phrql production enable below
    "aws_user_pools_web_client_id": "62no5eskm4a990lq9dro69srqc",
    //for phrql test enable below
    //"aws_user_pools_web_client_id": "6ft9a1rrj34mmcmrrqd9ec7sdo",
    //for phrql test enable below
    //"aws_user_pools_id": "us-west-2_chPPnHOzM",
    "oauth": {}
};


export default awsmobile;