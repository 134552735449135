import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Auth } from 'aws-amplify';
import { Plugins } from '@capacitor/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { FoodscriptionCommonService } from '../sharedFiles/foodscription-common.service';
import {  Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { DataService } from '../sharedFiles/data.service';

const { Storage } = Plugins;

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http:HttpClient,
              private navCtrl:NavController,
              private router:Router) { }

  appPlatform:string;
  username:string;
  userPreferenceId:number;
  accessToken:string;
  refreshToken:string;
  isAdmin:boolean;
  initiateChatConnection = new Subject;
  validation_messages = {
    'username': [
        { type: 'required', message: 'Username is required' }
      ],
      'password': [
        { type: 'required', message: 'Password is required' }
      ],
      'code': [
        { type: 'required', message: 'Code is required' }
      ],
      'blank' : [
        { message:'Both Username and Password must be provided'}
      ],
      'invalidUser' : [
        { message:'Username or Password is Invalid!'}
      ]
  }

  validations = new BehaviorSubject<any>(this.validation_messages);
  validationMessage = this.validations.asObservable();
  
  awsLogin(userInputData:any){
    return Auth.signIn(userInputData.email, userInputData.password);
  }

  awsLogout(){
    try {
         Auth.signOut({ global: true }).then(()=>{
          Storage.remove({key:'fslogin'});
          Storage.remove({key:'fsUserPreferenceId'});
         });
    } catch (error) {
        console.log('error signing out: ', error);
    }
  }

  awsForgotPassword(username:string){
    return Auth.forgotPassword(username);
  }

  awsForgotPasswordSubmit(username:string,code:string,newPassword:string){
      return Auth.forgotPasswordSubmit(username, code, newPassword);
  }

  autoLoginAWS(){
    return Auth.currentAuthenticatedUser().then(value => {
      return value;
    });
  }

  getCurrentUserDetails(){
      return this.http.get<any>("https://accounts.phrql.com/api/me/profile/",this.getHeadersObject());
  }

  getHeadersObject(){
    let headers = null;
    if(this.appPlatform === "web"){
        headers = {
          headers :  new HttpHeaders().set('Content-Type', 'application/json'),
          withCredentials:true
        }
    }else{
        headers = {
          headers :  new HttpHeaders().set('Content-Type', 'application/json').set('Authorization',`Bearer ${this.accessToken}`)
        }
    }
    return headers;
  }

  loginBasedOnDevice(fromRouterGaurd:boolean){
    if(this.appPlatform === "web"){
      return this.checkCookie(fromRouterGaurd);
    }else{
      return new Promise<boolean>((resolve,reject)=>{
        this._redirectToLogin().then(()=>resolve(true)).catch(()=> reject(false));
      });
    };
  }

  checkCookie(fromRouterGaurd:boolean) {
    let token= this.getCookie("csrftoken");
    if (token != "") {
      if(fromRouterGaurd){
        return true;
      }
      this.getCurrentUserDetails().subscribe((userDetails:any) => {
        this.userPreferenceId = userDetails.id;
        this.initiateChatConnection.next();
        this.navCtrl.navigateForward("/tabs/meals");
      });
    } else {
      window.location.href=environment.cookieAuth;
    }
  }

  getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  checkErrorForAuth(err:any){
    if(err.status === 403){
      if(this.appPlatform === "web"){
        this.router.navigateByUrl("/auth");
      }
      this.router.navigateByUrl("/auth/login");
    }
  }

  private _redirectToLogin(){
      return Auth.currentAuthenticatedUser().then( userData =>{
        this.accessToken = userData.signInUserSession.accessToken.jwtToken;
        this.refreshToken = userData.signInUserSession.refreshToken.token;
        this.getCurrentUserDetails().subscribe((profile:any) => {
          this.username = profile.email;
          console.log("username is "+this.username);
          this.userPreferenceId = profile.id;
          console.log("profile is ",profile);
          this.isAdmin = profile.isAdmin;
          this.initiateChatConnection.next();
          this.navCtrl.navigateForward("/tabs/meals");
        },err=>{
          this.checkErrorForAuth(err);
        });
        return true;
    }).catch(()=>{
      this.navCtrl.navigateForward("/auth/login");
    });
  }
}